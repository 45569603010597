<template>
  <div class="user">
    <div class="photo md-gutter">
      <md-avatar class="md-avatar-icon ">
        <md-icon>person</md-icon>
      </md-avatar>
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span>
          {{ title }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed & (0 === 1)">
          <ul class="nav">
            <slot>
              <li>
                <a href="#vue">
                  <span class="sidebar-mini">
                    {{ $t('user.edit_options_short') }}
                  </span>
                  <span class="sidebar-normal">
                    {{ $t('user.edit_options') }}
                  </span>
                </a>
              </li>
              <li>
                <a href="#vue">
                  <span class="sidebar-mini">
                    {{ $t('user.edit_profile_short') }}
                  </span>
                  <span class="sidebar-normal">
                    {{ $t('user.edit_profile') }}
                  </span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import {CollapseTransition} from 'vue2-transitions'

export default {
  components: {
    CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: 'No name'
    },

    avatar: {
      type: String,
      default: './img/faces/avatar.jpg'
    }
  },
  data() {
    return {
      isClosed: true
    }
  },
  methods: {
    clicked: function(e) {
      e.preventDefault()
    },
    toggleMenu: function() {
      this.isClosed = !this.isClosed
    }
  }
}
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
.user.photo {
  width: auto;
  height: auto;
}
</style>
